import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import { Container, Row, Col, Button } from 'react-bootstrap'

function HomeAdminScreen() {
  return (
    <div>
      teste
      
      não autenticado.
    </div>
  )
}

export default HomeAdminScreen
